'use strict';

angular.module('informaApp')
    .service(
        'SystemNotifications',
        function ($http, ToastrHelper, BaseApiService, ConstantsSvc) {
            const SystemNotificationMessages = ConstantsSvc.EVENTS.SystemNotification;

            function showNotificationSaveSuccess() {
                return ToastrHelper.showSuccess(SystemNotificationMessages.SaveSuccess);
            }

            function showNotificationSaveError() {
                return ToastrHelper.showSuccess(SystemNotificationMessages.SaveError);
            }

            function showNotificationDeleteSuccess() {
                return ToastrHelper.showSuccess(SystemNotificationMessages.DeleteSuccess);
            }

            function showNotificationDeleteError() {
                return ToastrHelper.showError(SystemNotificationMessages.DeleteError);
            }

            function handleNotificationSave(saveRequest) {
                return saveRequest
                    .then(showNotificationSaveSuccess)
                    .catch(showNotificationSaveError);
            }

            function getExistingSystemNotification() {
                return BaseApiService.get('system-notifications').then(({data}) => Promise.resolve(data));
            }

            function submitNotification(existingNotification, formValues) {
                const { expirationDate } = formValues;

                const preparedSystemNotification = {
                    ...formValues,
                    expirationDate: expirationDate.toDate().toDateString(),
                };

                if (existingNotification) {
                    const { id } = existingNotification;

                    return handleNotificationSave(BaseApiService.put(
                        `admin/system-notifications/${id}`,
                        {
                            id,
                            ...preparedSystemNotification
                        }
                    ));
                }

                return BaseApiService
                    .post('admin/system-notifications', preparedSystemNotification)
                    .then(({data}) => {
                        showNotificationSaveSuccess();

                        return Promise.resolve(data);
                    });
            }

            function deleteNotification(existingNotification) {
                if (!existingNotification || !existingNotification.id) {
                    return showNotificationDeleteError();
                }

                return BaseApiService
                    .delete(`admin/system-notifications/${existingNotification.id}`)
                    .then(() => {
                        showNotificationDeleteSuccess();

                        return Promise.resolve(true);
                    })
                    .catch(showNotificationDeleteError);
            }

            function convertToLondonTime(dateString) {
                return new Date(dateString).toLocaleString('en-US', {
                    timeZone: 'Europe/London'
                });
            }

            return {
                submitNotification,
                deleteNotification,
                getExistingSystemNotification,
                convertToLondonTime
            };
        }
    );